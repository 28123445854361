
import { Component, Prop, Vue } from "vue-property-decorator";
import POptionBar from "@/components/look/POptionBar.vue";
import PInfoBar from "@/components/look/PInfoBar.vue";
import VueFilters from "@/vue-filters";
import EntradasTable from "../entradas/EntradasTable.vue";
import SalidasTable from "../salidas/SalidasTable.vue";
import { EventBus } from "@/event-bus";
import { IDivisa, ISaldo } from "@/entities";
import { IColumnaResponse } from "@/services";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";
import { SweetAlertResult } from "sweetalert2";

@Component({
  components: {
    SalidasTable,
    EntradasTable,
    PInfoBar,
    POptionBar,
  },
  mixins: [VueFilters, VueMethods],
})
export default class SaldosModal extends Vue {
  @Prop({ required: true, default: null }) saldo!: ISaldo;
  @Prop({ required: true, default: {} }) entradasConfig!: IColumnaResponse;
  @Prop({ required: true, default: {} }) salidasConfig!: IColumnaResponse;
  @Prop({ required: false, default: false }) isBCR!: boolean;
  @Getter("getLocale") locale!: Locale;

  divisa?: IDivisa | null = null;

  entradas = false;
  salidas = false;
  verModal = false;

  totalEntradas = 0;
  totalElementsEntradas = 0;
  totalSalidas = 0;
  totalElementsSalidas = 0;

  get options(): { text: string; pressed: boolean; data: string }[] {
    return [
      {
        text: this.$t("title.saldos.modal.entradas"),
        pressed: this.entradas,
        data: "entradas",
      },
      {
        text: this.$t("title.saldos.modal.salidas"),
        pressed: this.salidas,
        data: "salidas",
      },
    ];
  }

  get detallesSaldo(): { label: string; text: string }[] {
    return [
      {
        label: this.$t("label.cliente"),
        text: this.saldo.clientexdel?.cliente?.desCliente || "",
      },
      {
        label: this.$t("label.delegacion"),
        text: this.saldo.clientexdel?.delegacion?.desDelegacion || "",
      },
      {
        label: this.$t("label.fecCertificado"),
        text: this.filterLocalDate(this.saldo.fecCertificado),
      },
      { label: this.$t("label.divisa"), text: this.saldo.divisa?.codDivisa || "" },
      {
        label: this.$t("label.saldoAnterior"),
        text: this.filterNumber(this.saldo.saldoAnterior, 2),
      },
      {
        label: this.$t("label.saldoActual"),
        text: this.filterNumber(this.saldo.saldoActual, 2),
      },
      {
        label: this.$t("label.entradas"),
        text: this.filterNumber(this.saldo.numEntradas, 2),
      },
      {
        label: this.$t("label.salidas"),
        text: this.filterNumber(this.saldo.numSalidas, 2),
      },
    ];
  }
  get modalTitle(): string {
    return this.entradas
      ? this.$t("title.saldos.modal.entradas")
      : this.$t("title.saldos.modal.salidas");
  }

  mounted(): void {
    EventBus.$on("open-modal-salidas", (saldo: ISaldo) => {
      this.$emit("update:saldo", saldo);
      this.divisa = saldo.divisa;
      this.entradas = false;
      this.salidas = true;
      this.verModal = true;
    });
    EventBus.$on("open-modal-entradas", (saldo: ISaldo) => {
      this.$emit("update:saldo", saldo);
      this.divisa = saldo.divisa;
      this.entradas = true;
      this.salidas = false;
      this.verModal = true;
    });
  }

  selectTable(table: string): void {
    if (table === "entradas") {
      this.salidas = false;
      this.entradas = true;
    } else if (table === "salidas") {
      this.salidas = true;
      this.entradas = false;
    }
  }
  action(action: { action: string; data?: any }): void {
    if (action.action === "download") {
      this.download({ pkSaldo: this.saldo.id, idioma: this.locale });
    }
  }

  async download(params: { pkSaldo: number; idioma: Locale }): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.entradas) {
        if (this.isBCR) {
          await this.$services.movimientos._downloadEntradasBCR(params);
        } else {
          await this.$services.movimientos._downloadEntradas(params);
        }
      } else if (this.isBCR) {
        await this.$services.movimientos._downloadSalidasBCR(params);
      } else {
        await this.$services.movimientos._downloadSalidas(params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async confirmDescarga(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.confirmarDescarga.title"),
      text: this.$t("label.confirmarDescarga.message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes"),
      cancelButtonText: this.$t("label.no"),
    });
  }
}
